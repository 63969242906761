


























































































































































































































































@import "./src/assets/css/project-variables";

.card-summary-table {
  color: $--color-primary;
  font-weight: bold;
}
